import request from '../utils/request'
import baseURL from '../utils/config'

// 得到省市区联动数据
export function GetProvinceCityArea(params) {
    return request({
        url: baseURL.baseURL + 'api/Dic/GetProvinceCityArea',
        method: 'get',
        params
    })
}
//  得到市信息
export function GetCity(params) {
    return request({
        url: baseURL.baseURL + 'api/Dic/GetCity',
        method: 'get',
        params
    })
}
//  得到code通过地区id
export function GetSiteCodeByName(params) {
    return request({
        url: baseURL.baseURL + 'api/Dic/GetSiteCodeByName',
        method: 'get',
        params
    })
}

