import request from '../utils/request'
import baseURL from '../utils/config'

//新增或者删除
export function AddOrEdit(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/AddOrEdit',
        method: 'post',
        data
    })
}
//得到详情
export function GetDetail(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/GetDetail',
        method: 'get',
        params
    })
}
//得到列表数据
export function GetList(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/GetList',
        method: 'post',
        data
    })
}

//删除
export function Delete(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/Delete',
        method: 'get',
        params
    })
}

//默认发货
export function SetDeliveryPoint(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/SetDeliveryPoint',
        method: 'get',
        params
    })
}

//默认自提
export function SetSelfLiftingPoint(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Address/SetSelfLiftingPoint',
        method: 'get',
        params
    })
}
