<template>
  <div v-loading="loading">
    <!-- 表格数据 -->
    <div class="right">
      <el-button type="primary" size="medium" @click="btn('add')"
        >新增</el-button
      >
    </div>
    <div class="divTb">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#cce1f9',
          color: '#505050',
          textAlign: 'center',
        }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="addressId" label="编号"> </el-table-column>
        <el-table-column prop="siteName" label="发货点名称"> </el-table-column>
        <el-table-column label="默认发货">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isDeliveryPoint"
              active-text="开"
              inactive-text="关"
              @change="btn('setDeliveryPoint', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="默认自提">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isSelfLiftingPoint"
              active-text="开"
              inactive-text="关"
              @change="btn('setSelfLiftingPoint', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="发货人姓名"> </el-table-column>
        <el-table-column label="地址">
          <template slot-scope="scope">
            {{ scope.row.province + scope.row.city + scope.row.area }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="联系电话"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="btn('edit', scope.row.addressId)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="btn('delete', scope.row.addressId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.index"
        :page-size="queryParam.size"
        layout="total, prev, pager, next"
        :total="queryParam.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="visible.dialog"
      @close="closeDialog"
    >
      <AddOrEdit
        :funcSucc="closeDialog"
        :editId="compParam.AddOrEdit.editId"
        v-if="visible.AddOrEdit"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  GetList,
  Delete,
  SetDeliveryPoint,
  SetSelfLiftingPoint,
} from "@/api/Address";
import AddOrEdit from "@/components/Address/AddOrEdit";
export default {
  data() {
    return {
      loading: false,
      //查询参数
      queryParam: {
        size: 10, //每页的数据量
        index: 1, //当前页数
        total: 1, //数据总量
      },
      //控制显示
      visible: {
        dialog: false,
        AddOrEdit: false,
      },
      dialogTitle: "新增",
      //组件参数存放地
      compParam: {
        AddOrEdit: {
          editId: 1234,
        },
      },
      tableData: [],
      currentPage: 1,
    };
  },
  components: {
    AddOrEdit,
  },
  methods: {
    //pageSize 改变时会触发
    btn(btnType, val) {
      switch (btnType) {
        case "query":
          this.loadData();
          console.log("查询");
          break;
        case "add":
          this.dialogTitle = "新增";
          this.compParam.AddOrEdit.editId = null;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("新增");
          break;
        case "edit":
          this.dialogTitle = "编辑";
          this.compParam.AddOrEdit.editId = val;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("编辑");
          break;
        case "delete":
          this.delete(val);
          console.log("删除");
          break;
        case "setDeliveryPoint":
          this.setDeliveryPoint(val);
          console.log("启用发货");
          break;
        case "setSelfLiftingPoint":
          this.setSelfLiftingPoint(val);
          console.log("启用自提");
          break;
      }
    },
    closeDialog(val) {
      console.log("关闭");
      this.loadData();
      this.visible.dialog = false;
      this.visible.AddOrEdit = false;
    },
    setDeliveryPoint(val) {
      let tip = "确定开启吗";
      if (val.isDeliveryPoint == false) {
        tip = "确定关闭吗";
      }
      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          SetDeliveryPoint({ id: val.addressId, enable: val.isDeliveryPoint })
            .then((res) => {
              this.loading = false;
              if (res.code == 10000) {
                this.$message.success("设置成功!");
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          val.enable = !val.enable;
        });
    },
    setSelfLiftingPoint(val) {
      let tip = "确定开启吗";
      if (val.isSelfLiftingPoint == false) {
        tip = "确定关闭吗";
      }
      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          SetSelfLiftingPoint({
            id: val.addressId,
            enable: val.isSelfLiftingPoint,
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 10000) {
                this.$message.success("设置成功!");
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          val.enable = !val.enable;
        });
    },
    delete(id) {
      //删除
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        Delete({ id: id })
          .then((res) => {
            this.loading = false;
            if (res.code == 10000) {
              this.$message.success("删除成功!");
              this.loadData();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    loadData() {
      this.loading = true;
      let postData = {
        pageSize: this.queryParam.size,
        pageIndex: this.queryParam.index,
        merchantId: 814
      };
      GetList(postData)
        .then((res) => {
          if (res.code == 10000) {
            this.tableData = res.data;
            this.queryParam.total = res.total;
            this.loading = false;
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
      this.loading = false;
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {},
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.queryParam.index = val;
      this.loadData();
    },
  },
  created: function () {
    //如果是修改，就加载数据
    this.loadData();
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>